<template>
  <div id="treatment-request">
    <CardHeader
      title="Solicitação de tratamento"
      :showPeriod="false"
      :options="[]"
      class="mb-3"
    />

    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      ref="scroll"
      v-if="patientTreatments.length"
    >
      <div class="treatment-container">
        <TreatmentRequest
          v-for="patientTreatment in patientTreatments"
          :key="patientTreatment.id"
          :patientTreatment="patientTreatment"
          :openAppointmentModal="openAppointmentModal"
        />
      </div>
    </perfect-scrollbar>

    <NoFilterResults
      v-else
      message="Nenhuma solicitação de tratamento encontrada"
    />

    <AppointmentModal
      ref="appointmentModal"
      :appointmentId="appointmentId"
      :patientProps="patient"
      @created-appointment="createdAppointment"
      @deleted-appointment="deletedAppointment"
      @updated-appointment="getPatientTreatments"
    />
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseEyes } from '@/utils/eyesHelper'

export default {
  name: 'TreatmentRequests',
  props: {
    eyeCareBIPatient: {
      type: Object,
      required: true
    }
  },
  components: {
    CardHeader: () => import('@/layouts/InvoicingTiss/Dashboard/CardHeader'),
    TreatmentRequest: () => import('@/components/Glauco/Dashboard/Patient/TreatmentRequest'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    AppointmentModal: () => import('@/components/AppointmentModal')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      patient: {},
      patientId: this.$route.params.patientId,
      patientTreatments: [],
      patientTreatmentDose: {},
      appointmentId: null,
      medicationOptions: []
    }
  },
  computed: {
    patientTreatment() {
      return this.patientTreatments.find(
        patientTreatment =>
          patientTreatment.id ===
          this.patientTreatmentDose?.patient_treatment_id
      )
    },
    medicationId() {
      return this.medicationOptions.find(
        medication =>
          medication.label === this.patientTreatmentDose?.procedure_drug
      )?.value
    }
  },
  async created() {
    await this.getGlaucoLaserMedications()
    await this.getGlaucoInjectionMedications()
  },
  methods: {
    parseEyes,
    async getPatientTreatments() {
      if (!this.eyeCareBIPatient.eyecare_bi_id) return
      try {
        const { data } = await this.api.getPatientTreatments(
          this.eyeCareBIPatient.eyecare_bi_id
        )
        this.patientTreatments = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async openAppointmentModal(patientTreatmentDose, appointmentId = null) {
      this.appointmentId = appointmentId
      this.patientTreatmentDose = patientTreatmentDose
      this.$bvModal.show('appointment-modal')
      if (this.appointmentId) return
      const patient = await this.getPerson()
      this.patient = patient
      this.$refs.appointmentModal.setPatient(patient)
    },
    async getPerson() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getPerson(
          this.eyeCareBIPatient?.eyecare_bi_id
        )
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async createdAppointment(appointment) {
      if (appointment?.patient_id === this.eyeCareBIPatient?.eyecare_bi_id) {
        const isLoading = this.$loading.show()
        await this.updatePatientTreatmentDose(this.patientTreatmentDose?.id, {
          appointment_id: appointment.id,
          date: appointment.start_datetime
        })

        if (!this.patientTreatment.scheduled_doses) {
          await this.createUserScheduledMedication()
        } else {
          await this.createAlertByUserTreatmentBI({
            start_date: appointment.start_datetime,
            treatment_start_date: this.patientTreatment.date,
            user_treatment_id: this.patientTreatment.user_treatment_glauco_id,
            clinic: this.clinic.name,
            doses_count: this.patientTreatment.doses.length,
            medication_id: this.medicationId
          })
        }
        await this.getPatientTreatments()
        isLoading.hide()
      }
    },
    async createUserScheduledMedication() {
      try {
        await this.api.createUserScheduledMedication({
          medication_id: this.medicationId,
          dosage_quantity: this.patientTreatment.doses.length,
          start_date: this.patientTreatment.date,
          user_treatment_id: this.patientTreatment.user_treatment_glauco_id,
          eyes: this.parseEyes(this.patientTreatmentDose.laterality),
          clinic: this.clinic.name
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async deletedAppointment() {
      const isLoading = this.$loading.show()
      await this.getPatientTreatments()
      isLoading.hide()
    },
    async updatePatientTreatmentDose(id, data) {
      try {
        await this.api.updatePatientTreatmentDose(id, data)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async createAlertByUserTreatmentBI(data) {
      try {
        await this.api.createAlertByUserTreatmentBI(data)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getGlaucoLaserMedications() {
      try {
        const response = await this.api.getGlaucoLaserMedications()
        this.medicationOptions = response.data.map(medication => ({
          value: medication.id,
          label: medication.medicine
        }))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getGlaucoInjectionMedications() {
      try {
        const response = await this.api.getGlaucoInjectionMedications()
        response.data.map(medication => {
          this.medicationOptions.push({
            value: medication.id,
            label: medication.medicine
          })
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  },
  watch: {
    eyeCareBIPatient: {
      async handler(newValue) {
        if (newValue?.id) {
          await this.getPatientTreatments()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
#treatment-request {
  padding: 30px 24px;
  margin: 0 auto;
  background-color: var(--neutral-000);
  border-radius: 8px;
  margin-top: 2%;
  margin-bottom: 2%;

  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }

  .treatment-container {
    max-height: 420px;
  }
}
</style>

export const parseLaterality = value => {
  return (
    {
      'Olho direito': 'O.D',
      'Olho esquerdo': 'O.E',
      'Ambos os olhos': 'A'
    }[value] || value
  )
}

export const parseEyes = eyes => {
  return (
    {
      'Olho direito': 'right',
      'Olho esquerdo': 'left',
      'Ambos os olhos': 'both'
    }[eyes] || eyes
  )
}
